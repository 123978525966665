<template>
  <div class="header">
    <div class="header-container">
    <div class="header-left">
      <img class="logo" @click="toHome" src="https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/movtile-logo.png"></img>
    </div>
    <div class="header-right">
      <router-link to="/">首页</router-link>
      <router-link to="/joinus">加入我们</router-link>
      <router-link to="/movtile">关于幕童</router-link>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    methods: {
      toHome(){
        this.$router.push('/')
      }
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="less" scoped>
  .header {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    align-items: center;
    z-index: 99;
    justify-content: center;

    .header-container{
      max-width: 2200px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 80px;
    }

    .header-left{
      .logo{
        cursor: pointer;
        width: 200px;
        height: 35px;
      }
    }
    .header-right{
      margin-left: auto;
      white-space: nowrap;
      a{
        position: relative;
        color: #fff;
        display: inline-block;
        line-height: 60px;
        height: 80px;
        margin-left: 35px;
        padding: 10px;
      }
      .router-link-exact-active{
        position: relative;
        display: inline-block;
        &::after{
          position: absolute;
          top: 53px;
          left: 0;
          content: '';
          height: 2px;
          width: calc( 100% - 20px );
          background-color: #fff;
          transform: translateX(10px);
          transition: all .3s ease;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header{
      .header-container{
        padding: 0 15px;
      }

      .header-left{
        width: 30%;
        .logo{
          width: 150px;
          height: auto;
        }
      }
      .header-right{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        a{
          font-size: 12px;
          line-height: 35px;
          height: 60px;
          margin-left: 0px;
          padding: 10px 5px;
        }
        .router-link-active{
          &::after{
            top: 38px;
            height: 1.5px;
            width: calc( 100% - 10px );
            transform: translateX(5px);
          }
        }
      }
    }
  }
</style>
