<template>
  <div class="footer">
    <div class="footer-img"></div>
    <div class="footer-container">
      <div class="footer-item us">
        <div class="title">联系我们</div>
        <p>工作时间：10:00 - 19:00（工作日）</p>
        <p>联系电话：010-53510161</p>
        <p>商务合作：service@movtile.com</p>
        <p>联系地址：北京市朝阳区半截塔路55号七棵树创意园C3栋</p>
      </div>
      <div class="footer-item yun">
        <div class="title">云服务支持</div>
        <img src="@/assets/images/support-aliyun.png" alt="" />
        <img src="@/assets/images/support-huawei.png" alt="" />
        <img src="@/assets/images/support-Tencent.png" alt="" />
      </div>
      <div class="footer-item wx">
        <div class="title">官方微信</div>
        <img
          src="https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/code.png"
          alt=""
        />
      </div>

      <!-- other -->
      <div class="img-list">
        <img
          @click="goProduct('https://jimu.movtile.com')"
          src="@/assets/images/logo-jimu.png"
          alt=""
        />
        <img
          @click="goProduct('https://ke.movtile.com')"
          src="@/assets/images/logo-youke.png"
          alt=""
        />
        <img
          @click="goProduct('https://io.movtile.com')"
          src="@/assets/images/logo-yunyue.png"
          alt=""
        />
        <img
          @click="goProduct('https://apps.apple.com/cn/app/id1561696716')"
          src="@/assets/images/logo-changji.png"
          alt=""
        />
      </div>

      <div class="copyright">
        <span>京公网安备 11010502041446号 京ICP备19053590号-2</span>
        <span>Copyright © 2010 - 2021 movtile. All Rights Reserved. 非影科技 版权所有</span>
        <img src="@/assets/images/logo-movtile.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
    methods: {
      goProduct(val) {
        window.open(val);
      },
    },
  };
</script>
<style lang="less" scoped>
  .footer {
    position: relative;
    width: 100%;
    // font-family: PingFang-Regular;
    .footer-img {
      background: url(https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/copyright-bg.jpg)
        no-repeat;
      background-size: cover;
      width: 100%;
      height: 540px;
    }
    .footer-container {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1600px;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 70px 0px;
      .footer-item {
        color: #fff;
        vertical-align: text-top;
        display: inline-block;
        .title {
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 60px;
        }
        p {
          font-size: 14px;
          line-height: 2.5;
        }
      }
      .us {
        width: 40%;
      }
      .yun {
        width: 40%;
        img {
          height: 30px;
          margin-right: 20px;
          display: inline-block;
        }
      }
      .wx {
        width: 20%;
        img {
          width: 170px;
          height: 170px;
        }
      }

      // other
      .img-list {
        margin-top: 10px;
        opacity: 0.45;
        cursor: pointer;
        img {
          height: 20px;
          margin-right: 30px;
        }
      }
      .copyright {
        margin-top: 40px;
        color: #fff;
        span {
          font-size: 14px;
          margin-right: 30px;
        }
        img {
          height: 38px;
          margin-left: 24%;
        }
      }
    }
  }
  @media screen and (max-width: 1536px) {
    .footer {
      .footer-img {
        height: 330px;
      }
      .footer-container {
        max-width: 1200px;
        padding: 50px 0px;
        .footer-item {
          .title {
            font-size: 16px;
            margin-bottom: 30px;
          }
          p {
            font-size: 12px;
          }
        }
        .yun {
          img {
            height: 25px;
            margin-right: 15px;
          }
        }
        .wx {
          img {
            width: 100px;
            height: 100px;
          }
        }

        // other
        .img-list {
          margin-top: 20px;
          img {
            height: 15px;
            margin-right: 20px;
          }
        }
        .copyright {
          margin-top: 15px;
          span {
            font-size: 12px;
          }
          img {
            height: 20px;
            margin-left: 18%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .footer {
      .footer-container {
        max-width: 800px;
        padding: 30px 0px;
        .footer-item {
          .title {
            margin-bottom: 20px;
          }
        }
        .yun {
          img {
            height: 20px;
            margin-right: 10px;
          }
        }
        .wx {
          img {
            width: 80px;
            height: 80px;
          }
        }
        .copyright {
          img {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .footer {
      .footer-img {
        height: 500px
      }
      .footer-container {
        max-width: 95%;
        .footer-item {
          display: block;
          width: 100%;
          font-size: 12px;
          margin-top: 10px;
          .title{
            font-size: 14px;
          }
        }
        .us {
          p {
            line-height: 1.9;
          }
        }
        .yun{
          img {
            height: 16px;
          }
        }
        .wx{
            img{
              width: 60px;
              height: 60px;
            }
          }
        .copyright{
          span{
            display: block;
            line-height: 1.5;
            &:nth-child(1){
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
